import {useState} from "react";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

export default function StorePage(props){
    const [filter, setFilter] = useState("none");
    const [items, setItems] = useState([]);

    function GetName(){
        switch(filter){
            case "none":
                return "All";
                default:
                    return filter;
        }
    }

    return (
            <div className={"flex flex-col h-full w-full overflow-y-auto overflow-x-hidden"}>
                    <div className={"flex h-full flex-row w-full overflow-hidden"}>
                        <div className={"flex flex-col h-full w-full text-white text-2xl lg:w-4/5 pt-6 pl-6 pb-6 gap-4 overflow-hidden"}>
                            <div className={"flex flex-row h-20 w-full bg-slate-600 rounded-xl"}>

                            </div>
                            <div className={"flex flex-row h-full w-full bg-slate-800 rounded-xl p-2 overflow-hidden"}>
                                <div className={"flex flex-col h-full w-full overflow-y-auto overflow-x-hidden gap-2"}>
                                <div className={"flex flex-wrap gap-3 h-auto w-full justify-center pt-4"}>
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                        <ProductCard />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={"hidden lg:flex flex-col h-full justify-start items-start text-white text-2xl lg:w-1/5 p-6"}>
                            <div className={"flex flex-col w-full h-2/3 bg-slate-600 rounded-xl"}>

                            </div>
                        </div>
                    </div>
            </div>
    )
}

function ProductCard(props){
    return (
        <div className={"flex flex-col h-[350px] w-[380px] bg-slate-500 rounded-2xl"}>
            <div className={"flex flex-row h-12 w-full justify-end items-center p-2"}>
                <div className="badge badge-primary p-2">Test Item</div>
            </div>
            <div className={"flex flex-row h-2/3 w-full pl-3 pr-3"}>
                    <div className={"flex h-full w-full bg-cover bg-center bg-[url('nighttime.png')] hover:opacity-60"}></div>
                </div>
            <div className={"flex flex-col h-1/3 w-full p-2 text-sm"}>
                <div className={"flex flex-row h-1/4 w-full p-2"}>
                    <div className={"flex flex-row h-full w-2/3  text-lg font-semibold"}>
                        Test Item
                    </div>
                    <div className={"flex flex-row h-full w-1/3 justify-end items-center font-bold text-xl"}>
                        $19.99
                    </div>
                </div>
                <div className={"flex flex-row h-3/4 w-full p-2 text-sm"}>
                    <div className={"flex flex-col h-full w-4/5 flex-wrap"}>
                        This is just a temporary test item. <br/>
                        test <br/>
                        test <br/>
                    </div>
                    <div className={"flex flex-row h-full w-1/5 text-xs justify-end items-center gap-1 "}>
                            <div className={"flex flex-row w-full h-auto bg-slate-400 justify-center items-center hover:opacity-75 p-2 tooltip tooltip-bottom tooltip-primary"} data-tip="Add to cart.">
                                <AddShoppingCartIcon className={"flex h-8 w-8"}/>
                        </div>
                            <div  className={"flex flex-row w-full h-auto bg-slate-400 justify-center items-center hover:opacity-75 p-2 tooltip tooltip-bottom tooltip-primary"} data-tip="Direct buy">
                                <PointOfSaleIcon className={"flex h-8 w-8"}/>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}