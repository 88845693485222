import {config} from "../config.js";

export default function Pricing(props){
    return (
        <div className="flex flex-col w-full h-full place-items-center pb-12 overflow-scroll-auto">
            <div className="flex flex-col h-20 items-center justify-center w-full py-5 text-center lg:py-6">
                <p className="text-sm uppercase">
                    Are you new here?
                </p>
                <h3 className="mt-1 text-xl font-semibold text-slate-600 dark:text-navy-100">
                    Welcome. Where do you like to Start?
                </h3>
            </div>
            <div className="flex flex-row w-full grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-5 lg:gap-6 items-center justify-center px-4">
                {config.products.map(el =>
                    <PriceCard el={el} />
                )}
            </div>
        </div>
    )
}

function PriceCard(props){
    const {el} = props;
    return (
        <div className="flex w-72 card p-4 bg-slate-800 border border-slate-400 text-center sm:p-5">
            <div className="mt-5">
                <h4 className="text-xl font-semibold text-white dark:text-navy-100">
                    {el.name}
                </h4>
                <p>{el.description}</p>
            </div>
            <div className="mt-5">
                <span className="text-4xl tracking-tight text-primary dark:text-accent-light">${el.price}</span>/month</div>
            <div className="mt-8 space-y-4">
                {config.items.map((el2,ind) => (
                    <div className="flex items-center space-x-3">
                        {el.items > ind ?
                        <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-primary/10 text-primary dark:bg-accent/10 dark:text-accent-light">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                            </svg>
                        </div> :
                            <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-warning/10 text-warning">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"/>
                                </svg>
                            </div>
                        }
                        <span className="font-medium">{el2}</span>
                    </div>
                ))}
            </div>
            <div className="mt-8">
                <button className="btn rounded-full border border-slate-200 font-medium text-primary hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-500 dark:text-accent-light dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                    Choose Plan
                </button>
            </div>
        </div>
    )
}