import React, {useState} from "react";
import { useEffect } from 'react'
import { themeChange } from 'theme-change'
import secureLocalStorage from "react-secure-storage";
import CookieIcon from '@mui/icons-material/Cookie';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const themes = ["aether", "light"];

export default function Footer(props) {
    const {user, adminEditMode, setAdminEditMode ,activeTheme, setActiveTheme} = props;
    const [expanded, setExpanded] = useState(false);

    function ToggleTheme(str){
        secureLocalStorage.setItem("activetheme", str);
        setActiveTheme(str);
    }

    return (
        <>
            <footer className={"footer p-10 sticky bottom-10 bg-base-200 text-base-content z-20 " + (expanded ? " " : "hidden")}>
                <nav>
                    <header className="footer-title">Services</header>
                    <a className="link link-hover">Branding</a>
                    <a className="link link-hover">Design</a>
                    <a className="link link-hover">Marketing</a>
                    <a className="link link-hover">Advertisement</a>
                </nav>
                <nav>
                    <header className="footer-title">Company</header>
                    <a className="link link-hover">About us</a>
                    <a className="link link-hover">Contact</a>
                    <a className="link link-hover">Jobs</a>
                    <a className="link link-hover">Press kit</a>
                </nav>
                <nav>
                    <header className="footer-title">Legal</header>
                    <a className="link link-hover">Terms of use</a>
                    <a className="link link-hover">Privacy policy</a>
                    <a className="link link-hover">Cookie policy</a>
                </nav>
            </footer>
            <footer className="footer h-10 sticky flex justify-center w-full flex-row items-center bottom-0 px-10 border-t bg-base-200 text-base-content border-base-300 z-50">
                <div className="flex flex-row justify-between items-center w-full h-10">
                    <div className="tooltip tooltip-right tooltip-primary flex w-auto" data-tip="Toggle the footer menu">
                        <input type="checkbox" className="toggle toggle-success mr-4" onChange={e => {
                            setExpanded(e.target.checked);
                        }}/>
                    </div>
                    <p>Copyright © AetherHouse Productions 2023-2025<br/></p>
                    <div className="hidden md:flex flex-row w-auto justify-center items-center gap-2 h-full self-end">
                        <div className="dropdown dropdown-top">
                            <div tabIndex={0} role="button" className="flex gap-1 w-auto m-1 h-full flex-row justify-center items-center">
                                Theme
                                <svg width="12px" height="12px"
                                     className="h-2 w-2 fill-current opacity-60 inline-block"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
                                    <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
                                </svg>
                            </div>
                            <ul tabIndex={0}
                                className="dropdown-content flex-col z-[1] p-2 shadow-2xl bg-base-200 rounded-box gap-2 w-52 border border-base-100">
                                {themes.map(theme => (
                                    <li key={theme}>
                                        <button onClick={() => {
                                            ToggleTheme(theme);
                                        }}
                                                className={" w-full text-center btn my-1 btn-primary " + (activeTheme === theme ? "" : "btn-outline")}>
                                            {theme}
                                        </button>
                                    </li>))}
                            </ul>
                        </div>
                        <a>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 className="fill-current">
                                <path
                                    d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
                            </svg>
                        </a>
                        <a>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 className="fill-current">
                                <path
                                    d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                            </svg>
                        </a>
                        <a>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 className="fill-current">
                                <path
                                    d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
                            </svg>
                        </a>
                        <div className={"w-auto h-auto flex flex-row tooltip"}
                             data-tip="Adjust privacy and cookies settings" onClick={() => {
                            window.Sddan.cmp.displayUI();
                        }}>
                           <CookieIcon />
                        </div>
                        {user && user.admin && <button className={"btn btn-primary w-auto h-auto flex flex-row tooltip " + (adminEditMode ? "" : "btn-outline")}
                             data-tip={"Toggle Admin mode " + (adminEditMode ? "off." : "on.")} onClick={() => {
                            setAdminEditMode(!adminEditMode);
                        }}>
                            <AdminPanelSettingsIcon className={"flex h-6 w-6"}/>
                        </button>}
                    </div>
                </div>
            </footer>
        </>
    )
}