import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);

export function rectifyFormat(s) {
    let b = s.split(/\D/);
    return new Date(`${b[0]}-${b[1]}-${b[2]}T${b[3]}:${b[4]}:${b[5]}.${b[6].substr(0, 3)}+00:00`);
}