import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const data = [{question: "Test Question 1", content: "Just testing a dynamic faq setup test 1"}, {question: "Test Question 2", content: "Just testing a dynamic faq setup test 2"}, {question: "Test Question 3", content: "Just testing a dynamic faq setup test 3"}]

export default function Faq(props) {
    const [ data2, setData ] = React.useState("test");

    return (
        <div className={"flex h-full w-full mx-auto my-auto bg-slate-600 bg-opacity-70 p-10"}>
            <div  className="flex-col hidden h-full md:flex w-1/3 md:w-1/4">
                {/*
                <Editor
                    apiKey={"hvzljhih4di59r6ymf02nbc49ucyc7litkskeomxj7n89xqk"}
                    value={data2}
                    onEditorChange={(e) => setData(e)}
                    init={{
                        statusbar: false,
                        plugins: 'ai anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                        mergetags_list: [
                            { value: 'First.Name', title: 'First Name' },
                            { value: 'Email', title: 'Email' },
                        ],
                        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                    }}
                />*/}
            </div>
            <div className="join join-vertical h-full w-full sm:2/3 md:w-3/4">
                <h2 className="mb-6 pl-6 text-3xl font-bold">Frequently asked questions</h2>
                {data.map(el => (
                    <div key={el.question} className="collapse collapse-arrow join-item border border-base-300">
                        <input type="radio" name="my-accordion-4"/>
                        <div className="collapse-title text-xl font-medium">
                            {el.question}
                        </div>
                        <div className="collapse-content">
                            <p>{el.content}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

function FAQ(props) {
    return (
        <div className="container my-24 mx-auto md:px-6 xl:px-24">
            <section className="mb-32">


                <div id="accordionFlushExample">
                    <div className="rounded-none border border-t-0 border-l-0 border-r-0 border-neutral-200">
                        <h2 className="mb-0" id="flush-headingOne">
                            <button
                                className="group relative flex w-full items-center rounded-none border-0 py-4 px-5 text-left text-base font-bold transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:text-primary-400"
                                type="button" data-te-collapse-init data-te-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                Anim pariatur cliche reprehenderit?
                                <span
                                    className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-[#8FAEE0] dark:group-[[data-te-collapse-collapsed]]:fill-[#eee]">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="!visible border-0" data-te-collapse-item
                             data-te-collapse-show
                             aria-labelledby="flush-headingOne" data-te-parent="#accordionFlushExample">
                            <div className="py-4 px-5 text-neutral-500 dark:text-neutral-300">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life
                                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                                non cupidatat skateboard dolor brunch. Food truck quinoa
                                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                                put a bird on it squid single-origin coffee nulla assumenda
                                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                                wes anderson cred nesciunt sapiente ea proident. Ad vegan
                                excepteur butcher vice lomo. Leggings occaecat craft beer
                                farm-to-table, raw denim aesthetic synth nesciunt you probably
                                haven't heard of them accusamus labore sustainable VHS.
                            </div>
                        </div>
                    </div>
                    <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200">
                        <h2 className="mb-0" id="flush-headingTwo">
                            <button
                                className="group relative flex w-full items-center rounded-none border-0 py-4 px-5 text-left text-base font-bold transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:text-primary-400"
                                type="button" data-te-collapse-init data-te-collapse-collapsed
                                data-te-target="#flush-collapseTwo"
                                aria-expanded="false" aria-controls="flush-collapseTwo">
                                Non cupidatat skateboard dolor brunch?
                                <span
                                    className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-[#8FAEE0] dark:group-[[data-te-collapse-collapsed]]:fill-[#eee]">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="!visible hidden border-0" data-te-collapse-item
                             aria-labelledby="flush-headingTwo" data-te-parent="#accordionFlushExample">
                            <div className="py-4 px-5 text-neutral-500 dark:text-neutral-300">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life
                                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                                non cupidatat skateboard dolor brunch. Food truck quinoa
                                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                                put a bird on it squid single-origin coffee nulla assumenda
                                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                                wes anderson cred nesciunt sapiente ea proident. Ad vegan
                                excepteur butcher vice lomo. Leggings occaecat craft beer
                                farm-to-table, raw denim aesthetic synth nesciunt you probably
                                haven't heard of them accusamus labore sustainable VHS.
                            </div>
                        </div>
                    </div>
                    <div className="rounded-none border border-l-0 border-r-0 border-b-0 border-t-0 border-neutral-200">
                        <h2 className="mb-0" id="flush-headingThree">
                            <button
                                className="group relative flex w-full items-center rounded-none border-0 py-4 px-5 text-left text-base font-bold transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:text-primary-400"
                                type="button" data-te-collapse-init data-te-collapse-collapsed
                                data-te-target="#flush-collapseThree"
                                aria-expanded="false" aria-controls="flush-collapseThree">
                                Praesentium voluptatibus temporibus consequatur non aspernatur?
                                <span
                                    className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-[#8FAEE0] dark:group-[[data-te-collapse-collapsed]]:fill-[#eee]">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="!visible hidden rounded-b-lg" data-te-collapse-item
                             aria-labelledby="flush-headingThree" data-te-parent="#accordionFlushExample">
                            <div className="py-4 px-5 text-neutral-500 dark:text-neutral-300">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
                                optio vitae inventore autem fugiat rerum sed laborum. Natus
                                recusandae laboriosam quos pariatur corrupti id dignissimos
                                deserunt, praesentium voluptatibus temporibus consequatur non
                                aspernatur laborum rerum nemo dolorem libero inventore provident
                                exercitationem sunt totam aperiam. Facere sunt quos commodi
                                obcaecati temporibus alias amet! Quam quisquam laboriosam quae
                                repellendus non cum adipisci odio?
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}