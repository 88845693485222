import {createTheme} from "@mui/material/styles";

export const bordercolor = "border-base-100"

export const lightTheme = createTheme({
    palette: {
        primary: {
            light: '#24D6AC',
            main: '#7ab3ff',
            dark: '#24D6AC',
            contrastText: '#bcfde9',
        },
        secondary: {
            light: '#28a187',
            main: '#4970aa',
            dark: '#0c3b31',
            contrastText: '#b6ead7',
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        primary: {
            light: '#24D6AC',
            main: '#24D6AC',
            dark: '#24D6AC',
            contrastText: '#bcfde9',
        },
        secondary: {
            light: '#199b83',
            main: '#1c6250',
            dark: '#0c3b31',
            contrastText: '#b6ead7',
        },
    },
});