export default function Frontpage(props) {
    return (
        <div className={"flex flex-col w-full h-full overflow-y-auto text-white"}>
            <div className={"flex flex-col w-full h-auto"}>

                <div className={"flex flex-col w-full h-screen"}>
                    <div className="flex carousel w-full h-full">

                        <div id="slide1" className="carousel-item relative w-full h-full bg-cover bg-center bg-[url('nighttime.png')]">
                            <div
                                className="absolute flex justify-between items-center h-full w-full">
                                <a href="#slide3" className="btn btn-circle">❮</a>
                                <div className={"flex flex-col w-3/5 h-auto justify-center items-center text-2xl p-10"}>
                                    <div className={"flex flex-col w-full h-full justify-center items-center"}>
                                        <div className="flex flex-col h-full w-full justify-around items-center bg-black bg-opacity-25 text-white p-10 gap-4">

                                            <h1 className="flex flex-col font-bold tracking-tight text-4xl">
                                            <span className="text-[hsl(218,81%,75%)] my-4">AetherHouse Productions</span> home is still under construction.</h1>
                                                <p className="tracking-tight text-xl my-2">check back later.</p>
                                                <div className="flex flex-row justify-center items-center">
                                                    <button className="btn btn-primary">Coming Soon</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="#slide2" className="btn btn-circle">❯</a>
                            </div>
                        </div>

                        <div id="slide2" className="carousel-item relative w-full h-full  bg-cover bg-center bg-[url('bgimage.png')]">
                            <div
                                className="absolute flex justify-between items-center transform -translate-y-1/2 left-5 right-5 top-1/2">
                                <a href="#slide1" className="btn btn-circle">❮</a>
                                <div className={"flex flex-col w-3/5 h-auto justify-center items-center text-2xl p-10"}>
                                    <div className={"flex flex-col w-full h-full justify-center items-center"}>
                                        <div
                                            className="flex flex-col h-full w-full justify-around items-center bg-black bg-opacity-25 text-white p-10 gap-4">

                                            <h1 className="flex flex-col font-bold tracking-tight text-4xl">
                                                <span
                                                    className="text-[hsl(218,81%,75%)] my-4">AetherHouse Productions</span> home
                                                is still under construction.</h1>
                                            <p className="tracking-tight text-xl my-2">check back later.</p>
                                            <div className="flex flex-row justify-center items-center">
                                                <button className="btn btn-primary">Coming Soon</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="#slide3" className="btn btn-circle">❯</a>
                            </div>
                        </div>

                        <div id="slide3"
                             className="carousel-item relative w-full h-full  bg-cover bg-center bg-[url('bgimage2.png')]">
                            <div
                                className="absolute flex justify-between items-center transform -translate-y-1/2 left-5 right-5 top-1/2">
                                <a href="#slide2" className="btn btn-circle">❮</a>
                                <div className={"flex flex-col w-3/5 h-auto justify-center items-center text-2xl p-10"}>
                                    <div className={"flex flex-col w-full h-full justify-center items-center"}>
                                        <div
                                            className="flex flex-col h-full w-full justify-around items-center bg-black bg-opacity-25 text-white p-10 gap-4">

                                            <h1 className="flex flex-col font-bold tracking-tight text-4xl">
                                                <span
                                                    className="text-[hsl(218,81%,75%)] my-4">AetherHouse Productions</span> home
                                                is still under construction.</h1>
                                            <p className="tracking-tight text-xl my-2">check back later.</p>
                                            <div className="flex flex-row justify-center items-center">
                                                <button className="btn btn-primary">Coming Soon</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="#slide4" className="btn btn-circle">❯</a>
                            </div>
                        </div>

                        <div id="slide4"
                             className="carousel-item relative w-full h-full bg-cover bg-center bg-[url('bgimage3.png')]">
                            <div
                                className="absolute flex justify-between items-center transform -translate-y-1/2 left-5 right-5 top-1/2">
                                <a href="#slide3" className="btn btn-circle">❮</a>
                                <div className={"flex flex-col w-3/5 h-auto justify-center items-center text-2xl p-10"}>
                                    <div className={"flex flex-col w-full h-full justify-center items-center"}>
                                        <div
                                            className="flex flex-col h-full w-full justify-around items-center bg-black bg-opacity-25 text-white p-10 gap-4">

                                            <h1 className="flex flex-col font-bold tracking-tight text-4xl">
                                                <span
                                                    className="text-[hsl(218,81%,75%)] my-4">AetherHouse Productions</span> home
                                                is still under construction.</h1>
                                            <p className="tracking-tight text-xl my-2">check back later.</p>
                                            <div className="flex flex-row justify-center items-center">
                                                <button className="btn btn-primary">Coming Soon</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="#slide1" className="btn btn-circle">❯</a>
                            </div>
                        </div>

                    </div>
                </div>

                <VideoHeader/>
            </div>
        </div>
    )
}

function VideoHeader(props) {
    const {setOpenPage} = props;
    return (
        <div className="px-6 py-12 text-center md:px-12 lg:text-left">
            <div className="container mx-auto">
                <div className="grid items-center gap-12 lg:grid-cols-2">
                    <div className="mt-12 lg:mt-0">
                        <h1 className="mb-12 text-3xl font-bold tracking-tight text-[hsl(218,81%,95%)] md:text-4xl xl:text-5xl">
                            Made for <span className="text-[hsl(218,81%,75%)]">Gamers.</span>
                            <br/>
                            <span>by Gamers.</span>
                        </h1>
                        <p className="text-2xl text-[hsl(218,81%,95%)]">
                            Start playing today
                        </p>
                    </div>
                    <div className="mb-12 lg:mb-0">
                        <div
                            className="embed-responsive embed-responsive-16by9 relative w-full overflow-hidden rounded-lg shadow-lg"
                            style={{paddingTop: "56.25%"}}>
                            <iframe
                                className="embed-responsive-item absolute top-0 right-0 bottom-0 left-0 h-full w-full"
                                src="/cutscene.mp4"
                                allowFullScreen={true} data-gtm-yt-inspected-2340190_699="true" id="240632615"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}