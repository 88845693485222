import {useState} from "react";
import {useForumContext} from "../contexts/forumContext";
import {useAppContext} from "../contexts/appContext";
import {EditorContent, useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import TextAlign from "@tiptap/extension-text-align";
import CharacterCount from "@tiptap/extension-character-count";
import MenuBar from "../editor/MenuBar";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";

const extensions = [
    StarterKit.configure(),
    Highlight,
    TaskList,
    TaskItem,
    TextAlign.configure({
        types: ['heading', 'paragraph'],
    }),
    CharacterCount.configure({
        limit: 10000,
    })
]

export default function NewPost(props){
    const {catLabel, openCat,setCreateNew} = useForumContext();
    const {profile} = useAppContext();
    const [tempTitle, setTempTitle] = useState("");
    const [tempSubTitle, setTempSubTitle] = useState("");
    const [html, setHtml] = useState("<p></p>")

    const editor = useEditor({
        extensions: extensions,
        editorProps: {
            attributes: {
                class: 'prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none',
            },
        },
    });

    async function PostNow(){
        let newpost = {};
        newpost.postCat = openCat;
        newpost.postTitle = tempTitle;
        newpost.posterName = profile().username;
        newpost.likes = 0;
        newpost.posterAvatar = profile().avatar;
        newpost.postContent = editor.getHTML();
        newpost.postSubtitle = tempSubTitle;
            const { error } = await supabase
                .from('forum')
                .insert(newpost)
            if(error){
                toast.error('Failed to create new post.', {
                    duration: 4000,
                    position: 'bottom-right',
                });
            } else {
                toast.success('Created new post successfully.', {
                    duration: 4000,
                    position: 'bottom-right',
                });
                setTempTitle("");
                setHtml("<p></p>");
                setCreateNew(false);
                setTempSubTitle("");
            }
    }

    return (
        <div className="flex flex-col w-full h-full p-4 bg-white dark:bg-slate-800 shadow-md rounded border border-slate-200 dark:border-slate-700">
            <div className="flex flex-row h-10 w-full">{"Create a new post in " + catLabel()} </div>
            <div className="relative w-full mb-2 mt-2">
                <label
                    className="block uppercase text-xs font-bold mb-2"
                    htmlFor="title"
                >
                    Title
                </label>
                <input value={tempTitle}
                       onChange={(e) => {
                           setTempTitle(e.currentTarget.value);
                       }}
                       type="text"
                       className="border-0 px-3 py-3 placeholder-gray-400 text-black bg-gray-400 rounded text-sm shadow focus:outline-none focus:ring w-full"
                       placeholder="Post title"
                       style={{transition: "all .15s ease"}}
                />
            </div>
            <div className="relative w-full mb-2 mt-2">
                <label
                    className="block uppercase text-xs font-bold mb-2"
                    htmlFor="title"
                >
                    Subtitle
                </label>
                <input value={tempSubTitle}
                       onChange={(e) => {
                           setTempSubTitle(e.currentTarget.value);
                       }}
                       type="text"
                       className="border-0 px-3 py-3 placeholder-gray-400 text-black bg-gray-400 rounded text-sm shadow focus:outline-none focus:ring w-full"
                       placeholder="Post title"
                       style={{transition: "all .15s ease"}}
                />
            </div>
            {editor && <MenuBar editor={editor}/>}
            {editor && <div className={"flex flex-col w-full h-full overflow-y-auto"}>
                <div className={"flex flex-col h-full w-full bg-gray-400 text-black"}>
                    <EditorContent className="editor__content" editor={editor}/>
                </div>
            </div>}
            <div className={"flex flex-row h-12 w-full justify-end gap-5 items-center"}>
                <button onClick={() => {
                    setTempTitle("");
                    setHtml("<p></p>");
                    setCreateNew(false);
                    setTempSubTitle("");
                }} className={"btn btn-error mt-4 w-[200px]"}>Cancel
                </button>
                <button onClick={() => {
                    PostNow();
                }} className={"btn btn-primary mt-4 w-[200px]"}>Post
                </button>
            </div>
        </div>
    )
}