import React, {useEffect, useState} from "react";
import parse from 'html-react-parser';
import { Editor } from '@tinymce/tinymce-react';
import {notify} from "../../components/notify";
import {config} from "../../config.js";

function getISOtime() {
    return new Date().toISOString().slice(0, 19).replace('T', ' ');
}

export default function EditableBlock(props){
    const {blockid, user, adminEditMode} = props;
    const [block, setBlock] = useState(null);
    const [editing, setEditing] = useState(false);
    const [blockClass, setBlockClass] = useState("");
    const [id, setID] = useState(blockid);
    const [title, setTitle] = useState("");
    const [editedBlockHTML, setEditedBlockHTML] = useState("");
    const [preview, setPreview] = useState(false);

    function GetBlock(str){
        fetch(config.apilink + "/api/blocks/get", {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({id: str}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === "success") {
                    setBlock(response.data);
                } else
                    setBlock({"id": blockid, "title": "New block", "content": "<p>New Block</p>", "editor": "", "edited": getISOtime(),"blockclass": "flex flex-col w-full h-auto text-white bg-slate-200"});
            })
            .catch((err) => console.error(err));
    }

    function SaveBlock(){
        let bd = {};
        bd.id = id;
        bd.title = title;
        bd.content = editedBlockHTML;
        bd.editor = user.accountname;
        bd.edited = getISOtime();
        bd.blockclass = blockClass;
        fetch(config.apilink + "/api/blocks/edit", {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({bd: bd}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === "success") {
                    notify({
                        status: "success",
                        msg: "Page edit was saved successfully.",
                    });
                    GetBlock(blockid);
                } else
                    notify({
                        status: "error",
                        msg: "Failed saving block.",
                    });
            })
            .catch((err) => console.error(err));
    }

    function resetBlock(){
        if(!block) return;
        setBlockClass(block.blockclass);
        setEditedBlockHTML(block.content);
        setTitle(block.title);
        setID(block.id);
    }

    useEffect(() => {
        if(blockid === "") return;
        GetBlock(blockid);
    }, [blockid]);

    useEffect(() => {
        resetBlock();
    }, [block]);

    return (
        <div
            className={editing ? "flex flex-col w-full h-auto min-h-[1200px] p-4 bg-slate-500" : "flex flex-col w-auto h-auto"}>
            <div className={"flex flex-row h-auto w-auto justify-end items-center gap-4"}>
                {editing && adminEditMode &&
                    <div
                        className={"flex flex-row w-36 h-10 bg-slate-400 my-4 text-white justify-center items-center hover:opacity-70"}
                        onClick={() => {
                            setPreview(!preview);
                        }}>
                        {preview ? "Close Preview" : "Show Preview"}
                    </div>
                }
                {adminEditMode &&
                    <div
                        className={"flex flex-row w-36 h-10 bg-slate-400 my-4 text-white justify-center items-center hover:opacity-70"}
                        onClick={() => {
                            setEditing(!editing);
                        }}>
                        {editing ? "Close" : "Edit"}
                    </div>
                }
            </div>
            {!editing && <div className={blockClass}>
                {parse(editedBlockHTML)}
            </div>}
            {editing && preview &&
                <div className={blockClass}>
                    <p>Preview:</p>
                    {parse(editedBlockHTML)}
                </div>
            }
            {editing && adminEditMode &&
                <div className={"flex flex-col w-full h-full p-4 mt-10"}>
                    <div className={"flex flex-row justify-center items-center w-auto h-24 p-4 text-2xl gap-4"}>
                        <div className={"flex flex-col w-1/3 h-full"}>
                            <label className="block text-sm font-medium mb-1" htmlFor="email">ID</label>
                            <input id="d" value={id} onChange={e => setID(e.target.value)}
                                   className="form-input w-full" type="text"/>
                        </div>
                        <div className={"flex flex-col w-1/3 h-full"}>
                            <label className="block text-sm font-medium mb-1" htmlFor="title">Title</label>
                            <input id="title" value={title} onChange={e => setTitle(e.target.value)}
                                   className="form-input w-full" type="text"/>
                        </div>
                        <div className={"flex flex-col w-1/3 h-full"}><label className="block text-sm font-medium mb-1" htmlFor="blockclass">Block CSS</label>
                            <input id="blockclass" value={blockClass} onChange={e => setBlockClass(e.target.value)}
                                   className="form-input w-full" type="text"/></div>
                    </div>
                    <Editor
                        apiKey={"hvzljhih4di59r6ymf02nbc49ucyc7litkskeomxj7n89xqk"}
                        value={editedBlockHTML}
                        onEditorChange={(e) => setEditedBlockHTML(e)}
                        init={{
                            resize: true,
                            min_height: 800,
                            advcode_inline: true,
                            codesample_global_prismjs: true,
                            codesample_languages: [
                                {text: 'HTML/XML', value: 'markup'},
                                {text: 'JavaScript', value: 'javascript'},
                                {text: 'CSS', value: 'css'},
                                {text: 'PHP', value: 'php'},
                                {text: 'Ruby', value: 'ruby'},
                                {text: 'Python', value: 'python'},
                                {text: 'Java', value: 'java'},
                                {text: 'C', value: 'c'},
                                {text: 'C#', value: 'csharp'},
                                {text: 'C++', value: 'cpp'}
                            ],
                            statusbar: false,
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table wordcount code',
                            toolbar: 'undo redo | fontfamily fontsize | bold italic underline strikethrough forecolor backcolor | link image media table | align lineheight | numlist bullist indent outdent | emoticons | removeformat | codesample code',
                        }}
                    />
                    <div className={"flex flex-row h-18 w-full justify-around items-center"}>
                        <p>Last edited by{block.editor} @ {block.edited}</p>
                        <div
                            className={"flex flex-row w-36 h-10 bg-slate-400 my-4 text-white self-end  justify-center items-center hover:opacity-70"}
                            onClick={() => {
                                resetBlock();
                            }}>
                            Reset Changes
                        </div>
                        <div
                            className={"flex flex-row w-36 h-10 bg-slate-400 my-4 text-white self-end  justify-center items-center hover:opacity-70"}
                            onClick={() => {
                                SaveBlock();
                                setEditing(false);
                            }}>
                            Save
                        </div>
                    </div>
                </div>}
        </div>
    )
}