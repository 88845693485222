import React, {useState} from "react";
import logo from "../assets/AetherHouseLogo.png";
import {Link} from "react-router-dom";

const inactivemenu = "flex flex-row w-auto h-auto text-white hover:text-[hsl(218,81%,75%)] text-2xl justify-center items-center uppercase";
const activemenu = "flex flex-row w-auto h-auto text-[hsl(218,81%,75%)] text-2xl justify-center items-center uppercase";

export default function Menu(props) {
    const {activePage, setActivePage, setOpenPage} = props;
    const [ahOpen, setAhOpen] = useState(false);

    return (
        <>
        <div className={"flex flex-row h-40 w-full border-b border-slate-500 justify-between items-center  bg-cover bg-center bg-[url('panorama_shottown_8K.jpg')]"}>
            <div className={"hidden lg:flex flex-row justify-between items-center w-full h-full"}>
                <div data-tip={"AetherHouse Productions"} className="flex flex-row h-full w-auto justify-center items-center bg-black bg-opacity-50 px-2 hover:opacity-70 tooltip tooltip-right tooltip-primary " onMouseEnter={() => setAhOpen(true)} onMouseLeave={() => setAhOpen(false)}>
                    <div className="ml-4 normal-case text-lg flex flex-row h-auto w-auto justify-center items-center">
                        <img src={logo} alt={""} className={"w-auto h-12 lg:h-14 mr-2"}/>
                    </div>
                </div>
                <div className={"hidden lg:flex flex-row w-full h-full justify-center items-end gap-10 pb-4 font-bold text-shadow shadow-black"}>
                    <div className={activePage === "home" ? activemenu : inactivemenu}
                         onClick={() => {
                             setActivePage("home");
                         }}>
                        Home
                    </div>
                    <div className={activePage === "news" ? activemenu : inactivemenu}
                         onClick={() => {
                             setActivePage("news");
                         }}>
                        News
                    </div>
                    <div className={activePage === "map" ? activemenu : inactivemenu}
                         onClick={() => {
                             setActivePage("map");
                         }}>
                        Interactive Map
                    </div>
                    <div className={activePage === "info" ? activemenu : inactivemenu}
                         onClick={() => {
                             setActivePage("info");
                         }}>
                        Game Info
                    </div>
                    <div className={activePage === "store" ? activemenu : inactivemenu}
                         onClick={() => {
                             setActivePage("store");
                         }}>
                        Store
                    </div>
                </div>
            </div>
            <div
                className={"flex flex-col h-full w-full lg:w-96 lg:justify-end lg:items-end items-center justify-center"}>
                <div className={"h-1/2 w-full bg-cover bg-center"} style={{backgroundImage: "url('aeflogo.png')"}}/>
            </div>
        </div>
            {ahOpen && <div className={"absolute h-auto w-[400px] flex flex-col rounded-xl bg-black bg-opacity-80 top-28 left-0 p-4"} onMouseEnter={() => setAhOpen(true)} onMouseLeave={() => setAhOpen(false)}>
                <Link to={"/"}>
                    <button className={"btn btn-primary h-12 w-auto justify-center items-center text-xl my-4"}>Visit
                        AetherHouse Productions
                    </button>
                </Link>
            </div>}
        </>
    )
}