import NewsFeed from "../news/newsfeed";
import {useAppContext} from "../contexts/appContext";

export default function NewsPage(props) {
    const {openPage} = useAppContext();

    return (
        <div className={"flex flex-wrap h-full w-full items-center overflow-hidden" + (openPage() === "newsonly" ? " justify-center" : " justify-between")}>
            {openPage() !== "newsonly" &&  <div className={"hidden lg:flex flex-col w-[13%] h-full"}>
                <div className={"h-full w-full rounded-r-3xl bg-cover opacity-35"} alt={""}
                     style={{backgroundImage: 'url("left.png")'}}/>
            </div>}
            <NewsFeed/>
            {openPage() !== "newsonly" &&   <div className={"hidden lg:flex flex-col w-[13%] h-full"}>
                <div className={"h-full w-full rounded-l-3xl bg-cover opacity-35"} alt={""}
                     style={{backgroundImage: 'url("left.png")'}}/>
            </div>}
        </div>
    )
}