import {createContext, useContext, useEffect, useState} from "react";
import {supabase} from "../utils/supabase";
import { useLocation } from 'react-router-dom';
import toast from "react-hot-toast";

const AppContext = createContext({});
const appname = "AetherHouse Productions";

// @ts-ignore
const AppContextProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userPanelOpen, setUserPanelOpen] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [userlistOpen, setuserListOpen] = useState(false);
    const [friends, setFriends] = useState([]);
    const [serverList, setServerList] = useState([]);

    function profile() {
        if (session) {
            let prof = {};
            prof.id = session.user.id;
            prof.username = session.user.user_metadata.full_name;
            prof.email = session.user.email;
            prof.avatar = session.user.user_metadata.avatar_url;
            prof.discordID = session.user.user_metadata.provider_id;
            return prof;
        } else {
            return {};
        }
    }

    async function GetServers(){
        const { data, error } = await supabase
            .from('activeservers')
            .select()
        if(error){
            setServerList([]);
        } else {
            if(data.length > 0){
                setServerList(data);
            } else {
                setServerList([]);
            }
        }
    }

    async function GetMyData(){
        const { data, error } = await supabase
            .from('userdata')
            .select()
            .eq('user_id', session.user.id);
        if(error){
            setFriends([]);
        } else {
            if(data.length > 0){
                setFriends(data[0].friends);
            } else {
                await supabase
                    .from('userdata')
                    .insert({ friends: []});
                setFriends([]);
            }
        }
    }

    let route = useLocation();
    if(route.pathname === "/") {
        document.title = appname + ' || Home';
    } else {
        let rt = route.pathname.split("/")[1];
        let fl = rt.charAt(0).toUpperCase();
        let fr = rt.slice(1);
        document.title = appname + ' || ' + (fl + fr);
    }

    const openPage = () => {
        if (route.pathname === "/") {
            return "home";
        } else {
            return route.pathname.split("/")[1];
        }
    }

    useEffect(() => {
        if (isInitialLoad) {
            supabase.auth.getSession().then(({data: {session: any}}) => {
                setSession(session);
            })
        }
        const sub = supabase.auth.onAuthStateChange(async(_event, _session) => {
            setSession(_session);
            if (!_session) {
                setIsAdmin(false);
                if (isInitialLoad) setIsInitialLoad(false);
                if (loadingInitial) setLoadingInitial(false);
            }
        });
        GetServers();
        return (() => {
            sub.data.subscription.unsubscribe();
        });
    }, []);

    useEffect(() => {
        async function run(){
            await supabase.rpc('is_admin').then(result => {
                if(result.error){
                    setIsAdmin(false);
                } else setIsAdmin(result.data);
            });
            GetMyData();
            if (isInitialLoad) setIsInitialLoad(false);
            if (loadingInitial) setLoadingInitial(false);
        }

        if(session){
            run();
        }
    }, [session]);

    return (
        <AppContext.Provider
            value={{
                session,
                profile,
                isAdmin,
                loadingInitial,
                userPanelOpen,
                setUserPanelOpen,
                friends,
                openPage,
                appname,
                chatOpen,
                setChatOpen,
                userlistOpen,
                setuserListOpen,
                serverList,
                GetServers
            }} >
            {children}
        </AppContext.Provider>)
}

const useAppContext = () => useContext(AppContext);

export { AppContext as default, AppContextProvider, useAppContext };