import {useForumContext} from "../contexts/forumContext";
import {rectifyFormat} from "../utils/supabase";

export default function ForumRightContent(props){
    const {loadingForum, visiblePosts, setVisiblePosts, openCat, setOpenCat, openPost, setOpenPost} = useForumContext();

    return (
        <div className="md:flex flex-col w-full h-full hidden xl:block xl:w-72 py-8">
            <div className="flex flex-col h-full w-full overflow-hidden no-scrollbar">
                <div className="flex flex-col h-full w-full justify-between">
                    {/* Blocks */}

                    {openPost ?
                        <div className="flex flex-col h-full w-full justify-start items-center gap-10">
                            {/* Post Info */}
                            <div className="flex flex-col h-auto w-full bg-slate-50 dark:bg-slate-800/20 p-4 rounded border border-slate-200 dark:border-slate-700">
                                <div
                                    className="flex w-full flex-row text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-4">
                                    <div className="flex flex-row h-10 w-full justify-start gap-2 items-center mt-1.5">
                                        <img className="w-8 h-8 rounded-full" src={openPost.posterAvatar} width="32"
                                             height="32"
                                             alt={openPost.posterName}/>
                                        <p className={"flex flex-row text-xl h-full justify-center items-center"}>{openPost.posterName}</p>
                                    </div>
                                </div>
                                <p className={"flex flex-row text-sm h-8 w-full justify-start items-center"}>Posted
                                    on</p>
                                <p className={"flex flex-row text-xs h-8 w-full justify-center items-center italic"}>{rectifyFormat(openPost.created_at).toString()}</p>
                            </div>
                            {openPost && <div className={"flex flex-row h-10 w-full justify-center items-center"}>
                                <button className={"flex flex-row btn w-full btn-primary"} onClick={() => setOpenPost(null)}>Close Post</button>
                            </div>}
                        </div>
                        :
                        <div className="space-y-4">
                            {/* Block 2 */}
                            <div
                                className="bg-slate-50 dark:bg-slate-800/20 p-4 rounded border border-slate-200 dark:border-slate-700">
                                <div
                                    className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-4">Events
                                </div>
                                <ul>
                                    {/* Event 1 */}
                                    <li className="relative pb-4 last-of-type:pb-0">
                                        <div className="pl-6">
                                            <div
                                                className="text-xs font-medium uppercase text-indigo-600 mb-0.5">07/02/2024
                                                    - 15:00
                                                </div>
                                                <div className="text-sm mb-2">
                                                    <a className="font-medium text-slate-800 hover:text-slate-900 dark:text-slate-100 dark:hover:text-white"
                                                       href="#0">
                                                        Info Release party
                                                    </a>
                                                </div>
                                                {/* Avatars */}
                                                <div className="flex items-center space-x-2">
                                                    <div className="flex -space-x-3 -ml-0.5">
                                                        <img
                                                            className="rounded-full border-2 border-white dark:border-slate-800 box-content"
                                                            src={"bgimage.png"}
                                                            width="28"
                                                            height="28"
                                                            alt="User 02"
                                                        />
                                                        <img
                                                            className="rounded-full border-2 border-white dark:border-slate-800 box-content"
                                                            src={"bgimage.png"}
                                                            width="28"
                                                            height="28"
                                                            alt="User 03"
                                                        />
                                                        <img
                                                            className="rounded-full border-2 border-white dark:border-slate-800 box-content"
                                                            src={"bgimage.png"}
                                                            width="28"
                                                            height="28"
                                                            alt="User 04"
                                                        />
                                                    </div>
                                                    <div
                                                        className="text-xs font-medium text-slate-400 dark:text-slate-500 italic">+22
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Timeline element */}
                                            <div aria-hidden="true">
                                                <div
                                                    className="absolute top-0.5 -bottom-1 left-0.5 ml-px w-0.5 bg-slate-200 dark:bg-slate-700"/>
                                                <div
                                                    className="absolute top-0.5 left-0 -ml-0.5 w-3 h-3 rounded-full bg-slate-400 dark:bg-slate-700 border-2 border-slate-50 dark:border-slate-900"/>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <button
                                            className="btn-sm w-full bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500 shadow-none">View
                                            All
                                        </button>
                                    </div>
                                </div>

                                {/* Block 3 */}
                                <div
                                    className="bg-slate-50 dark:bg-slate-800/20 p-4 rounded border border-slate-200 dark:border-slate-700">
                                    <div
                                        className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-4">Popular
                                        Posts
                                    </div>
                                    <ul className="space-y-3">
                                        <li>
                                            <div className="text-sm mb-1">
                                                <a className="font-medium text-slate-800 hover:text-slate-900 dark:text-slate-100 dark:hover:text-white"
                                                   href="#0">
                                                    Beginner Guide 🚀
                                                </a>
                                            </div>
                                            <div className="text-xs text-slate-500">
                                                <a className="font-medium text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                                                   href="#0">
                                                    Xenthy
                                                </a>{' '}
                                                · 1d · 0 comments
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <button
                                            className="btn-sm w-full bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500 shadow-none">View
                                            All
                                        </button>
                                    </div>
                                </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}