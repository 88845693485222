import ForumLeftContent from "./Forumleft.jsx";
import ForumEntries from "./forumentries.jsx";
import ForumRightContent from "./forumright.jsx";
import {useForumContext} from "../contexts/forumContext";
import {useState} from "react";
import NewPost from "./newpost";
import ForumPost from "./forumpost";

export default function Forums(props){
    const {loadingForum, GetPosts, openCat, setOpenCat, createNew, setCreateNew, openPost} = useForumContext();

    return (
        <div className="flex h-full w-full px-2 overflow-y-hidden">

            {/* Left + Middle content */}
            <div className="flex h-full w-full md:flex-row flex-col">

                {/* Left content */}
                <ForumLeftContent/>

                {/* Middle content */}
                <div className="flex h-full w-full md:ml-8 xl:mx-4 2xl:mx-8">
                    {openCat === "dash" ?
                        <></>
                        :
                        <div className="flex flex-col w-full h-full pt-2">
                            {/* Forum Entries */}
                            <div className="flex flex-col h-full w-full space-y-2 overflow-y-auto px-4 pt-2 pb-3">
                                {createNew ? <NewPost /> : (openPost ? <ForumPost /> : <ForumEntries/>)}
                            </div>
                            {!createNew && !openPost && <div className={"flex flex-row h-12 w-full justify-end items-center"}>
                                    <button onClick={() => {
                                        setCreateNew(true)
                                    }} className={"btn btn-primary "}>Create Post</button>
                            </div>}
                        </div>
                    }
                </div>
            </div>
            <ForumRightContent/>
        </div>
    )
}