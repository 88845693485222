import logo from '../assets/AetherHouseLogo.png'
import logo2 from '../assets/AetherHouseBanner.png'
import Logindrawer, {UserMenu} from "./logindrawer.jsx";
import React, {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from "react-router-dom";
import {useAppContext} from "../contexts/appContext";

export function NavBarNew(props){
    const {userPanelOpen, setUserPanelOpen} = useAppContext();
    return (
        <nav className="absolute flex flex-row min-h-18 h-auto lg:h-20 bg-base-200 border-b-2 border-base-300 text-slate-300 w-full z-40 p-2">
            <div className="w-full h-full flex flex-row justify-start items-start">
                <div className="flex flex-row h-full w-auto justify-start items-start">
                    <div className="ml-4 normal-case text-lg flex flex-row h-full w-auto justify-center items-center ">
                        <img src={logo} alt={""} className={"w-auto h-12 lg:h-14 mr-2"}/>
                    </div>
                </div>

                <div className={"lg:hidden flex flex-row items-start justify-start w-full h-auto px-4"}>
                    <NewMenuMobile />
                    <UserMenu />
                </div>

                <div className="hidden lg:flex flex-row items-center justify-end w-full h-auto px-4">
                    <NewMenu />
                    <UserMenu />
                </div>
            </div>
        </nav>
    )
}

function NewMenu(props) {
    const {openPage} = useAppContext();
    return (
        <div className={"flex flex-row h-full w-auto gap-4 px-4 justify-center items-center mr-8"}>
            <Link to={"/"}>
                <button className={"btn btn-primary " + (openPage() === "home" ? "" : "btn-outline")}>Home
                </button>
            </Link>
            <Link to={"/news"}>
                <button className={"btn btn-primary " + (openPage()  === "news" ? "" : "btn-outline")}>News
                </button>
            </Link>
            <TitlesMenu />
            <Link to={"/services"}>
                <button className={"btn btn-primary " + (openPage()  === "services" ? "" : "btn-outline")}>Services
                </button>
            </Link>
            <SocialMenu />
        </div>
    )
}

function NewMenuMobile(props) {
    const {openPage} = useAppContext();
    const [open, setOpen] = React.useState(false);
    return(
           <div className={"flex flex-col h-auto w-full gap-2 px-4 justify-center items-center"}>
                <button
                    className={"btn btn-primary " + (open ?  "" : "btn-outline")}
                    onClick={() => setOpen(!open)}
                >
                    <MenuIcon />
                </button>
               {open &&<>
               <Link to={"/"}>
               <button className={"btn btn-primary w-full " + (openPage()  === "home" ?  "" : "btn-outline")}>Home</button>
               </Link>
                   <Link to={"/news"}>
                    <button className={"btn btn-primary w-full " + (openPage()  === "news" ?  "" : "btn-outline")}>News</button>
                   </Link>
                   <Link to={"/aetherfract"}>
                   <button className={"btn btn-primary w-full " + (openPage()  === "aetherfract" ? "" : "btn-outline")}>Aetherfract</button>
                   </Link>
                       <Link to={"/services"}>
                        <button className={"btn btn-primary w-full " + (openPage()  === "services" ?   "" : "btn-outline")}>Services</button>
                   </Link>
                   <p>Social</p>
               <Link to={"/forums"}>
               <button className={"w-full btn btn-primary " + (openPage()  === "forums" ? "btn-primary" : "btn-outline btn-default")}>Forums</button>
               </Link>
               </>}
           </div>
    )
}

function TitlesMenu(props){
    const {openPage} = useAppContext();
        const [open, setOpen] = React.useState(false);

        function subActive(str){
            return str.includes(openPage());
        }

        return (
            <div className={"flex flex-col h-auto w-auto py-2"}>
                <button
                    className={"btn btn-primary w-full " + (subActive(["aetherfract"]) || open ? "" : "btn-outline")}
                    onClick={() => setOpen(!open)}>
                    Titles
                </button>
                {open &&
                    <div onMouseLeave={(e) => setOpen(false)} className={"absolute flex flex-col top-16 right-56 h-auto w-48 bg-slate-700 justify-start items-center rounded-lg p-4"}>
                        <Link to={"/aetherfract"}>
                            <button className={"btn w-full btn-primary " + (openPage()  === "aetherfract" ? "" : "btn-outline")}>Aetherfract
                            </button>
                        </Link>
                    </div>}
            </div>
        );
}

function SocialMenu(props) {
    const [open, setOpen] = React.useState(false);
    const {openPage} = useAppContext();
    function subActive(str){
        return str.includes(openPage());
    }

    return (
        <div className={"flex flex-col h-auto w-auto py-2"}>
            <button
                className={"btn btn-primary w-full " + (subActive(["forums"]) || open ? "" : "btn-outline")}
                onClick={() => setOpen(!open)}>
                Social
            </button>
            {open &&
                <div onMouseLeave={(e) => setOpen(false)} className={"absolute flex flex-col top-16 right-10 h-auto w-48 bg-slate-700 justify-start items-center rounded-lg p-4"}>
                    <Link to={"/forums"}>
                        <button  className={"btn w-full btn-primary " + (openPage() === "forums" ? "" : "btn-outline")}>Forums</button>
                    </Link>
                </div>}
        </div>
    );
}