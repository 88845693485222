import {useState} from "react";
import {useAppContext} from "../contexts/appContext";
import {Avatar, Button, List, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";

export default function ProfileWindow(props) {
    const {session, profile, userPanelOpen, setUserPanelOpen} = useAppContext();
    const [newPass,setNewPass] = useState("");
    const [newPass2,setNewPass2] = useState("");

    async function ResetPass(){
        if(newPass.length < 8 || newPass !== newPass2) return;
        await supabase.auth.updateUser({ password: newPass }).then((result) => {
            if(result.error){
                toast.error('Failed to change password: ' + result.error.message, {
                    duration: 4000,
                    position: 'bottom-right',
                });
            } else {
                toast.success('Password changed successfully.', {
                    duration: 4000,
                    position: 'bottom-right',
                });
                setNewPass("");
            }
        });
    }

    return (
        <div className={"flex flex-col w-full h-full justify-center items-center p-10 bg-base-100"}>
            <div className={"flex flex-col w-full h-full overflow-y-auto"}>
                <div className={"flex flex-col w-full h-auto text-textprimary"}>
                    <div className={"flex flex-row h-12 text-lg justify-start items-center font-bold"}>Account
                        Details
                    </div>
                    <div className={"flex flex-row h-12 w-full justify-between"}>
                        <p className={"flex flex-row h-full justify-center items-center text-center"}>ID:</p>
                        <p className={"flex flex-row h-full justify-center items-center text-xs text-center"}>{profile().id}</p>
                    </div>
                    <div className={"flex flex-row h-12 w-full justify-between"}>
                        <p className={"flex flex-row h-full justify-center items-center text-center"}>Provider ID:</p>
                        <p className={"flex flex-row h-full justify-center items-center text-xs text-center"}>{profile().discordID}</p>
                    </div>
                    <div className={"flex flex-row h-12 w-full justify-between"}>
                        <p className={"flex flex-row h-full justify-center items-center text-center"}>Username:</p>
                        <p className={"flex flex-row h-full justify-center items-center text-xs text-center"}>{profile().username}</p>
                    </div>
                    <div className={"flex flex-row h-12 w-full justify-between"}>
                        <p className={"flex flex-row h-full justify-center items-center text-center"}>Email:</p>
                        <p className={"flex flex-row h-full justify-center items-center text-xs text-center"}>{profile().email}</p>
                    </div>
                    <div className={"flex flex-row h-12 text-lg justify-start items-center font-bold"}>Contacts
                    </div>
                    <div className={"flex flex-col w-full h-[300px] overflow-y-auto"}>
                        <CheckboxListSecondary/>
                    </div>
                </div>
            </div>
            <div className={"flex flex-row h-10 w-full items-center justify-center py2 gap-3"}><p className={"w-[100px]"}>Password</p><input value={newPass} type={"password"} onChange={(e) => setNewPass(e.target.value)}/></div>
            <div className={"flex flex-row h-10 w-full items-center justify-center py2 gap-3"}><p className={"w-[100px]"}>Confirm</p><input value={newPass2} type={"password"} onChange={(e) => setNewPass2(e.target.value)}/></div>
            <div className={"flex flex-row h-16 w-full justify-center items-center"}>
                <Button onClick={() => {
                    ResetPass()
                }}>Change Password</Button>
            </div>
            <div className={"flex flex-row h-16 w-full justify-center items-center"}>
                <Button onClick={() => {
                    supabase.auth.signOut();
                    toast.success('Logged out.', {
                        duration: 4000,
                        position: 'bottom-right',
                    });
                }}>Log out</Button>
            </div>
        </div>
    );
}


function CheckboxListSecondary() {
    const {friends, profile} = useAppContext();
    const [checked, setChecked] = useState([1]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <List dense sx={{ width: '100%', height: 'auto' }}>
            <ListItem
                key={profile().id}
                disablePadding
            >
                <ListItemButton>
                    <ListItemAvatar>
                        <Avatar
                            alt={`Avatar n°${profile().avatar}`}
                            src={profile().avatar}
                        />
                    </ListItemAvatar>
                    <ListItemText id={profile().id} primary={profile().username} />
                </ListItemButton>
            </ListItem>
            {friends && friends.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                    <ListItem key={value} disablePadding>
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar
                                    alt={`Avatar n°${value + 1}`}
                                    src={`/static/images/avatar/${value + 1}.jpg`}
                                />
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}