import {useForumContext} from "../contexts/forumContext";

export default function ForumLeftContent(props){
    const {loadingForum, GetPosts, openCat, setOpenCat, catLabel, setOpenPost, openPost} = useForumContext();

    return (
        <div className="w-full md:w-60 mb-8 md:mb-0">
            <div
                className="md:sticky md:top-16 md:h-[calc(100dvh-64px)] overflow-x-hidden overflow-y-auto no-scrollbar">
                <div className="flex flex-row-reverse justify-between md:justify-start md:flex-col md:py-8">
                    <div className="flex justify-between items-center md:block">
                        {/* Block 1 */}
                        <div className="bg-slate-50 dark:bg-slate-800/20 p-4 rounded border border-slate-200 dark:border-slate-700">
                            <div
                                className=" flex flex-row justify-center items-center text-lg font-semibold text-primary uppercase">{catLabel()}
                            </div>
                        </div>
                    </div>

                    {/* Links */}
                    <div
                        className="flex flex-nowrap no-scrollbar md:block px-4 md:space-y-3 -mx-4">
                        {/* Group 1 */}
                        <div>
                            <div
                                className="hidden md:flex text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3 md:sr-only">Menu
                            </div>
                            <ul className="menu w-56 rounded-box overflow-hidden">
                                <li className={"rounded-box " + (openCat === "dash" ? "bg-base-200" : "")}>
                                    <a onClick={() => { setOpenCat("dash") }}>
                                        Dashboard
                                    </a>
                                </li>
                                <li>
                                    <details open>
                                        <summary className={openCat === "general" ? "bg-base-200" : ""}>General</summary>
                                        <ul>
                                            <li className={openCat === "general" ? "bg-base-100" : ""}>
                                                <a onClick={() => { setOpenCat("general") }}>General Chat</a>
                                            </li>
                                        </ul>
                                    </details>
                                </li>
                                <li>
                                    <details open>
                                        <summary className={openCat === "supportaetherfract" ? "bg-base-200" : "" || openCat === "supportgeneral" ? "bg-base-200" : ""}>Support</summary>
                                        <ul>
                                            <li className={openCat === "supportgeneral" ? "bg-base-100" : ""}>
                                                <a onClick={() => {
                                                    setOpenCat("supportgeneral")
                                                }}>General Support</a>
                                            </li>
                                            <li className={openCat === "supportaetherfract" ? "bg-base-100" : ""}>
                                                <a onClick={() => {
                                                    setOpenCat("supportaetherfract")
                                                }}>Aetherfract Support</a>
                                            </li>
                                        </ul>
                                    </details>
                                </li>
                            </ul>
                        </div>
                        {/* Group 2 */}
                        <div className={"hidden"}>
                            <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">My
                                Groups
                            </div>
                            <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap" href="#0">
                                        <svg className="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-3"
                                             viewBox="0 0 12 12">
                                            <path
                                                d="M6 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 2A6 6 0 1 1 6 0a6 6 0 0 1 0 12Z"/>
                                        </svg>
                                        <span
                                            className="text-sm font-medium text-slate-600 dark:text-slate-300">Startups</span>
                                    </a>
                                </li>
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap" href="#0">
                                        <svg className="w-3 h-3 shrink-0 fill-current text-rose-500 mr-3"
                                             viewBox="0 0 12 12">
                                            <path
                                                d="M6 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 2A6 6 0 1 1 6 0a6 6 0 0 1 0 12Z"/>
                                        </svg>
                                        <span
                                            className="text-sm font-medium text-slate-600 dark:text-slate-300">Javascript</span>
                                    </a>
                                </li>
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap" href="#0">
                                        <svg className="w-3 h-3 shrink-0 fill-current text-amber-500 mr-3"
                                             viewBox="0 0 12 12">
                                            <path
                                                d="M6 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 2A6 6 0 1 1 6 0a6 6 0 0 1 0 12Z"/>
                                        </svg>
                                        <span
                                            className="text-sm font-medium text-slate-600 dark:text-slate-300">Productivity</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}