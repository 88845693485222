import {supabase} from "../utils/supabase";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const ForumContext = createContext({});

const ForumContextProvider = ({ children }) => {
    const [forumPosts, setForumPosts] = useState([]);
    const [loadingForum, setLoadingForum] = useState(false);
    const [openCat, setOpenCat] = useState("dash");
    const [openPost, setOpenPost] = useState(null);
    const [createNew, setCreateNew] = useState(false);
    const [visiblePosts, setVisiblePosts] = useState([]);

    async function GetPosts(){
        let posts = [];
        if(forumPosts.length > 0){
            for(let i = 0; i < forumPosts.length; i++){
                if(forumPosts[i].postCat === openCat) {
                    let post = forumPosts[i];
                    const { data, error } = await supabase
                        .from('forumcomments')
                        .select()
                        .eq("postid", post.id);
                    if(error){
                        post.comments = [];
                    } else {
                        post.comments = data;
                    }
                    posts.push(post);
                }
            }
        }
        setOpenPost(null);
        setVisiblePosts(posts);
        setLoadingForum(false);
    }

    useEffect(() => {
        GetPosts();
    }, [openCat, forumPosts]);

    useEffect(() => {
        GetForum();
        const channel = supabase
            .channel('forum')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: "forum"
                },
                (payload) => GetForum()
            )
            .subscribe()
        return () => {
            supabase.removeChannel(channel);
        };
    }, []);

    async function GetForum() {
        setLoadingForum(true);
        try {
            const { data, error } = await supabase
                .from('forum')
                .select()
            if(error){
                setLoadingForum(false);
            } else {
                setForumPosts(data);
            }
        } catch (error) {
            setLoadingForum(false);
        }
    }

    function catLabel(){
        switch(openCat){
            case "dash":
                return "Dashboard";
            case "general":
                return "General Chat";
            case "supportgeneral":
                return "General Support";
            case "supportaetherfract":
                return "Aetherfract Support";
        }
    }

    return (
        <ForumContext.Provider
            value={{
                loadingForum,
                openCat,
                setOpenCat,
                catLabel,
                createNew,
                setCreateNew,
                visiblePosts,
                setVisiblePosts,
                openPost,
                setOpenPost
            }}>
            {children}
        </ForumContext.Provider>
    )
}

const useForumContext = () => useContext(ForumContext);

export { ForumContext as default, ForumContextProvider, useForumContext };