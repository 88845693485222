import {ArrowLeftOnRectangleIcon} from "@heroicons/react/20/solid/index.js";
import React, {useState} from "react";
import {notify} from "./notify.jsx";
import logo from '../assets/AetherHouseLogo.png'
import {config} from "../config.js";
import {useAppContext} from "../contexts/appContext";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";

export function UserMenu(props) {
    const {session, profile, userPanelOpen, setUserPanelOpen} = useAppContext();
    const [loginOpen, setLoginOpen] = useState(false);

    return (
        <div className={"flex justify-center items-center"}>
            {session ?
                    <div className="tooltip tooltip-left tooltip-primary"
                         data-tip={"Logged in as: " + profile().username}
                         onClick={() => {
                             setUserPanelOpen(!userPanelOpen);
                         }}>
                        <label tabIndex={0} className={"btn btn-circle avatar " + (userPanelOpen ? "btn-primary" : "btn-ghost")}>
                            <div className="w-10 rounded-full">
                                <img alt={`Avatar n°${profile().avatar}`}
                                     src={profile().avatar}/>
                            </div>
                        </label>
                    </div>
                :
                <Logindrawer loginOpen={loginOpen} setLoginOpen={setLoginOpen}/>
            }
        </div>
    )
}
export default function Logindrawer(props) {
    const {loginOpen, setLoginOpen} = props;
    const {session} = useAppContext();

    return (
        <>
            <div className="flex flex-row w-auto h-full justify-center items-center mr-4">
                    <button onClick={() => {
                        if(!session) {
                        setLoginOpen(!loginOpen);
                        }
                    }} data-tip="Login" className={"btn btn-primary tooltip tooltip-bottom tooltip-primary " + (loginOpen ? "" : "btn-outline")}>
                        <div className="w-10 rounded-full justify-center items-center flex-row">
                            <ArrowLeftOnRectangleIcon
                                className={"w-10 h-10"}/>
                        </div>
                    </button>
            </div>

            {loginOpen && <div className="absolute right-0 justify-start gap-2 pt-5 items-center bg-base-200 flex-col top-16 lg:top-20 h-[200px] flex w-full lg:w-[250px] border-b-2 border-l-2 border-r-2 border-base-300 rounded-bl-xl">
                <div className={"flex flex-row justify-around items-center w-[80%] h-10 bg-primary"} onClick={() => {
                    supabase.auth.signInWithOAuth({
                        provider: 'discord'
                    }).then(result => {
                        if(result.error){
                            toast.error('Failed to log in.', {
                                duration: 4000,
                                position: 'bottom-right',
                            });
                        } else {

                        }
                    })
                }}>
                    Discord
                </div>
                <div className={"flex flex-row justify-around items-center w-[80%] h-10 bg-primary"}
                onClick={() => {
                    supabase.auth.signInWithOAuth({
                        provider: 'google'
                    }).then(result => {
                        if(result.error){
                            toast.error('Failed to log in.', {
                                duration: 4000,
                                position: 'bottom-right',
                            });
                        } else {

                        }
                    })
                }}>
                    Google
                </div>
            </div>}
        </>
    )
}