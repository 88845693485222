import {useEffect, useState} from 'react'
import {NavBarNew} from "./components/navbar.jsx";
import Footer from "./components/footer.jsx";
import secureLocalStorage from "react-secure-storage";
import ProfileWindow from "./components/profilewindow.jsx";
import Faq from "./components/faq.jsx";
import Frontpage from "./pages/frontpage.jsx";
import Pricing from "./pages/pricing.jsx";
import Forums from "./forums/forum.jsx";
import NewsFeed from "./pages/news.jsx";
import WorldMap from "./pages/worldmap.jsx";
import Aetherfract from "./aetherfract/aetherfract.jsx";
import PasswordReset from "./pages/pwreset.jsx";
import Privacy from "./pages/privacy";
import AvatarCreatorWindow from "./pages/avatarcreatorwindow.jsx";
import {Route, Routes} from "react-router-dom";
import {useAppContext} from "./contexts/appContext";
import toast, { Toaster } from 'react-hot-toast';
import Services from "./pages/services";
import Support from "./pages/support";
import NewsPage from "./pages/news.jsx";

function App() {
    const {session, openPage, userPanelOpen, setUserPanelOpen} = useAppContext();
    const [activeTheme, setActiveTheme] = useState("dark");
    const [adminEditMode, setAdminEditMode] = useState(false);


    useEffect(() => {
        let dm = secureLocalStorage.getItem("activetheme");
        if(dm !== null) setActiveTheme(dm);
        const noSelectElements = document.querySelectorAll(".no-select");
        noSelectElements.forEach((element) => {
            element.style.webkitUserSelect = "none";
            element.style.mozUserSelect = "none";
            element.style.msUserSelect = "none";
            element.style.userSelect = "none";
        });
    }, []);

    return (
            <div className={"flex flex-col overscroll-none h-screen w-screen overflow-hidden bg-gradient-to-br from-[#1c252c] to-[#0a0f13]"} data-theme={activeTheme}>
                {openPage() === "" ?
                    <div className={"flex flex-col h-full w-full"}>
                        <div className="flex flex-col gap-4 w-52">
                            <div className="skeleton h-32 w-full"></div>
                            <div className="skeleton h-4 w-28"></div>
                            <div className="skeleton h-4 w-full"></div>
                            <div className="skeleton h-4 w-full"></div>
                        </div>
                    </div> :
                    <>
                    {(openPage() !== "aetherfract" && openPage() !== "newsonly") && <div className={"flex flex-row h-20 w-full overflow-hidden mb-2"}>
                        <NavBarNew/>
                    </div>}
                        <div className={"flex flex-row h-full w-full overflow-hidden"}>
                            <div className={"flex flex-col h-full overflow-hidden " + (userPanelOpen ? "w-4/5" : "w-full")}>
                                <Routes>
                                    <Route path='/' element={<Frontpage/>}/>
                                    <Route path='/aetherfract' element={<Aetherfract/>}/>
                                    <Route path='/faq' element={<Faq/>}/>
                                    <Route path='/prices' element={<Pricing/>}/>
                                    <Route path='/privacy' element={<Privacy/>}/>
                                    <Route path='/forums' element={<Forums/>}/>
                                    <Route path='/news' element={<NewsPage/>}/>
                                    <Route path='/newsonly' element={<NewsPage/>}/>
                                    <Route path='/map' element={<WorldMap/>}/>
                                    <Route path='/services' element={<Services />}/>
                                    <Route path='/support' element={<Support />}/>
                                </Routes>
                            </div>
                            {session && userPanelOpen &&
                                <div className={"flex flex-col h-full w-1/5 border-l-2 border-base-300"}>
                                    <ProfileWindow/>
                                </div>}
                        </div>
                    </>
                    }
                    <Footer setAdminEditMode={setAdminEditMode} adminEditMode={adminEditMode} activeTheme={activeTheme}
                            setActiveTheme={setActiveTheme}/>
                    <Toaster/>

                    </div>
                    )
                }

                export default App
