import React, {useEffect, useState} from "react";
import EditableBlock from "./pageblock.jsx";

const activesection = "flex flex-row w-full h-12 border border-slate-500 bg-slate-400 hover:opacity-75 justify-center items-center";
const inactivesection = "flex flex-row w-full h-12 border border-slate-500 bg-slate-600 hover:opacity-75 justify-center items-center";

const sections = [
    { name: "overview", title: "Game Overview"},
    { name: "crafting", title: "Crafting System"}
]

const pages = [
    {
        "section": "overview",
        "name": "overview",
        "title": "Game Overview",
        "path": "Game Overview/Overview",
    },
    {
        "section": "overview",
        "name": "environment",
        "title": "Environments Overview",
        "path": "Game Overview/Environment",
    },
    {
        "section": "overview",
        "name": "skills",
        "title": "Skills Overview",
        "path": "Game Overview/Skills",
    },
    {
        "section": "overview",
        "name": "crafting",
        "title": "Crafting Overview",
        "path": "Game Overview/Crafting",
    },
    {
        "section": "crafting",
        "name": "craftingoverview",
        "title": "Crafting System",
        "path": "Crafting System/Overview",
    },
    {
        "section": "crafting",
        "name": "craftingtools",
        "title": "Tools",
        "path": "Crafting System/Tools",
    }
];


export default function GameInfo(props) {
    const {user, adminEditMode} = props;
    const [section, setSection] = useState("overview");
    return (
        <div className={"flex flex-row h-full w-full overflow-hidden"}>
            <div
                className={"hidden lg:flex flex-col h-full w-64 bg-slate-700 border-r border-slate-500"}>
               <SideBar section={section} setSection={setSection} adminEditMode={adminEditMode} />
            </div>
            <div className={"flex flex-col h-full w-full overflow-y-auto"}>
                <div className={"flex flex-col h-auto w-full"}>
                    <OpenPage adminEditMode={adminEditMode} section={section} setSection={setSection} user={user} />
                </div>
            </div>
        </div>
    )
}

function  OpenPage(props) {
    const {section, setSection, user, adminEditMode} = props;
    const [page, setPage] = useState({});

    useEffect(() => {
        setPage(getpage(section));
    }, [section]);

    return (
        <div className={"flex flex-col h-screen w-full"}>

            <div className={"flex flex-row w-full h-18 p-4 text-xl justify-between"}>
                <div className={"flex flex-row w-1/3 h-full p-4 text-xl uppercase italic justify-start items-end"}>
                    {page.path}
                </div>
                <div
                    className={"flex flex-row w-2/3 lg:w-1/3 h-full p-4 text-3xl justify-center items-center font-bold"}>
                    {page.title}
                </div>
                <div className={"hidden lg:flex flex-row w-1/3 h-full p-4 text-xl justify-center items-center"}>

                </div>
            </div>
            <div className={"flex flex-row w-full h-1 px-4"}>
                <div className={"flex flex-row h-1/3 w-full bg-black bg-opacity-30"}></div>
            </div>
            <EditableBlock adminEditMode={adminEditMode} user={user} blockid={page.name}/>
        </div>
    )
}

function getpage(str) {
    for (let i = 0; i < pages.length; i++) {
        if (pages[i].name === str) return pages[i];
    }

    pages.map(el => {
        if (el.name === str) return el.path;
    });
}

function SideBar(props) {
    const {section, setSection} = props;
    return (
        <>
            <div className={"flex flex-row w-full h-20 text-2xl border-b border-black border-opacity-40 text-white justify-center items-center uppercase"}>
                Compendium
            </div>
            <div className={"flex flex-row w-full h-10"}>
            </div>
            {sections.map(el => (
                <div key={el.name} className="collapse collapse-arrow">
                    <input type="radio" name="my-accordion-4"/>
                    <div  className="collapse-title text-xl text-center font-medium">
                        {el.title}
                    </div>
                    <div className="collapse-content">
                        <div className={"flex flex-col h-auto w-full justify-center items-center px-1"}>
                            {pages.map(el2 => (el2.section === el.name &&
                                <div key={el2.name} className={section === el2.name ? activesection : inactivesection}
                                     onClick={() => {
                                         setSection(el2.name);
                                     }}
                                >
                                    {el2.title}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}