import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {AppContextProvider} from "./contexts/appContext";
import {ForumContextProvider} from "./contexts/forumContext";
import './editor/editor.scss';
import {NewsContextProvider} from "./contexts/newsContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <AppContextProvider>
              <NewsContextProvider >
                  <ForumContextProvider>
                      <App />
                  </ForumContextProvider>
              </NewsContextProvider>
          </AppContextProvider>
      </BrowserRouter>
  </React.StrictMode>
);