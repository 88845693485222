import Menu from "./menu.jsx";
import {useState} from "react";
import WorldMap from "../pages/worldmap.jsx";
import GameInfo from "./info/gameinfo.jsx";
import StorePage from "./store/store.jsx";
import NewsFeed from "./feed.jsx";

export default function Aetherfract(props){
    const {user, adminEditMode, setOpenPage} = props;
    const [activePage, setActivePage] = useState("home");

    return (
            <div className={"flex flex-col w-full h-full overflow-hidden "}>
                <Menu setOpenPage={setOpenPage} activePage={activePage} setActivePage={setActivePage} />
                {activePage === "home" && <FrontPage activePage={activePage} setActivePage={setActivePage} />}
                {activePage === "news" && <NewsFeed user={user} />}
                {activePage === "info" && <GameInfo adminEditMode={adminEditMode} user={user} />}
                {activePage === "map" && <WorldMap />}
                {activePage === "store" && <StorePage user={user} />}
            </div>
    )
}

function FrontPage(props){
    const {setActivePage} = props;
    return (
        <div className={"flex flex-col w-full h-full overflow-y-auto"}>
            <div className={"flex flex-col w-full h-auto background-radial-gradient"}>
                <div className={"flex flex-row w-full h-1/3"}>
                    <div className={"flex flex-col w-2/5 h-full p-10  justify-center items-center"}>
                        <img src={"bgimage3.png"} className={"flex h-4/5 w-auto"} alt={""}/>
                    </div>
                    <div className={"flex flex-col w-3/5 h-full p-10 justify-center items-center"}>
                        <h2 className="mb-16 text-3xl font-bold">
                            Join the Aetherfract universe soon.
                        </h2>
                    </div>
                </div>
                <VideoHeader setActivePage={setActivePage}/>
            </div>
        </div>
    )
}

function VideoHeader(props) {
    const {setActivePage} = props;

    return (
        <div className="flex h-screen w-full flex-col px-6 py-12 gap-10 text-center md:px-12 lg:text-left justify-around items-around">
            <div className="flex container mx-auto">
                <div className="grid items-center gap-12 lg:grid-cols-2 ">
                    <div className="mt-12 lg:mt-0">
                        <h1 className="mb-12 text-3xl font-bold tracking-tight text-[hsl(218,81%,95%)] md:text-4xl xl:text-5xl">
                            Made for <span className="text-[hsl(218,81%,75%)]">Gamers.</span>
                            <br/>
                            <span>by Gamers.</span>
                        </h1>
                    </div>
                    <div className="flex flex-row h-full justify-center items-center relative w-full overflow-hidden rounded-lg shadow-lg">
                        <video className={"flex flex-row h-full w-full"} controls={true} muted={true} autoPlay={true} loop={true} id="media">
                            <source src="/cutscene.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>
            <Block1/>
        </div>
    )
}

function Block1(props){
    return (
        <div className="h-auto w-full flex-col container mx-auto text-center lg:text-left xl:px-32 items-center justify-center">
            <h2 className="mb-16 text-3xl font-bold">
                Join the Aetherfract universe soon.
            </h2>
            <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">

                <div className="mb-12 md:mb-0 bg-black bg-opacity-30  text-center p-6">
                    <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                             stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"/>
                        </svg>
                    </div>
                    <h5 className="mb-4 text-lg font-bold">Explore and Discover</h5>
                    <p className="text-neutral-500 dark:text-neutral-300">
                        Explore rich hand crafted environments and discover the hidden mysteries of the universe of aetherfract.
                    </p>
                </div>

                <div className="mb-12 md:mb-0 bg-black bg-opacity-30  text-center p-6">
                    <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                             stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"/>
                        </svg>
                    </div>
                    <h5 className="mb-4 text-lg font-bold">Crafting</h5>
                    <p className="text-neutral-500 dark:text-neutral-300">
                        Gather materials and craft advanced items and structures.
                        Players can construct their own living areas.
                    </p>
                </div>

                <div className="mb-12 md:mb-0 bg-black bg-opacity-30  text-center p-6">
                    <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                             stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"/>
                        </svg>
                    </div>
                    <h5 className="mb-4 text-lg font-bold">Dynamic Group Content</h5>
                    <p className="text-neutral-500 dark:text-neutral-300">
                        Random 5-player instances spawn throughout the universe for groups to discover and conquer.
                        Larger group based content such as raids are planned in the future.
                    </p>
                </div>
            </div>
        </div>
    )
}