import React, {useState} from "react";

export default function NewsFeed(props) {
    const {user} = props;
    const [article, setArticle] = useState("overview");

    return (
        <div className={"flex flex-row h-full w-full overflow-hidden"}>
            <div className={"flex flex-col h-full w-full overflow-y-auto"}>
                <div className={"flex flex-col h-auto w-full"}>
                    <main className="grow">
                        <div className="px-4 sm:px-6 lg:px-8 py-8 md:py-0 w-full max-w-9xl mx-auto">

                            <div className="xl:flex">

                                {/* Left + Middle content */}
                                <div className="md:flex flex-1">

                                    {/* Left content */}
                                    <FeedLeftContent/>

                                    {/* Middle content */}
                                    <div className="flex-1 md:ml-8 xl:mx-4 2xl:mx-8">
                                        <div className="md:py-8">

                                            {/* Blocks */}
                                            <div className="space-y-4">

                                                {/* Posts */}
                                                <FeedPosts/>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {/* Right content */}
                                <FeedRightContent/>

                            </div>

                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

function FeedPosts(props){
    return (
        <>
            {/* Post 1 */}
            <div className="bg-white dark:bg-slate-800 shadow-md rounded border border-slate-200 dark:border-slate-700 p-5">
                {/* Header */}
                <header className="flex justify-between items-start space-x-3 mb-3">
                    {/* User */}
                    <div className="flex items-start space-x-3">
                        <img className="rounded-full shrink-0" src={"aeflogo.png"} width="40" height="40" alt="User 03" />
                        <div>
                            <div className="leading-tight">
                                <a className="text-sm font-semibold text-slate-800 dark:text-slate-100" href="#0">
                                    Xenthy
                                </a>
                            </div>
                            <div className="text-xs text-slate-500">Yesterday at 10:48 AM</div>
                        </div>
                    </div>
                </header>
                {/* Body */}
                <div className="text-sm text-slate-800 dark:text-slate-100 space-y-2 mb-5">
                    <p>👋</p>
                    <p>
                        Test feed item
                    </p>
                </div>
                {/* Footer */}
                <footer className="flex items-center space-x-4">
                    {/* Like button */}
                    <button className="flex items-center text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                        <svg className="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                            <path d="M14.682 2.318A4.485 4.485 0 0011.5 1 4.377 4.377 0 008 2.707 4.383 4.383 0 004.5 1a4.5 4.5 0 00-3.182 7.682L8 15l6.682-6.318a4.5 4.5 0 000-6.364zm-1.4 4.933L8 12.247l-5.285-5A2.5 2.5 0 014.5 3c1.437 0 2.312.681 3.5 2.625C9.187 3.681 10.062 3 11.5 3a2.5 2.5 0 011.785 4.251h-.003z" />
                        </svg>
                        <div className="text-sm text-slate-500 dark:text-slate-400">4</div>
                    </button>
                    {/* Share button */}
                    <button className="flex items-center text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                        <svg className="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                            <path d="M13 7h2v6a1 1 0 0 1-1 1H4v2l-4-3 4-3v2h9V7ZM3 9H1V3a1 1 0 0 1 1-1h10V0l4 3-4 3V4H3v5Z" />
                        </svg>
                        <div className="text-sm text-slate-500 dark:text-slate-400">44</div>
                    </button>
                    {/* Replies button */}
                    <button className="flex items-center text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                        <svg className="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                            <path d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                        </svg>
                        <div className="text-sm text-slate-500 dark:text-slate-400">7</div>
                    </button>
                </footer>
            </div>

            {/* Post 2 */}
            <div className="bg-white dark:bg-slate-800 shadow-md rounded border border-slate-200 dark:border-slate-700 p-5">
                {/* Header */}
                <header className="flex justify-between items-start space-x-3 mb-3">
                    {/* User */}
                    <div className="flex items-start space-x-3">
                        <img className="rounded-full shrink-0" src={"aeflogo.png"} width="40" height="40" alt="User 06" />
                        <div>
                            <div className="leading-tight">
                                <a className="text-sm font-semibold text-slate-800 dark:text-slate-100" href="#0">
                                    Xenthy
                                </a>
                            </div>
                            <div className="inline-flex items-center">
                                <svg className="w-3 h-3 shrink-0 fill-yellow-500 mr-1" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.953 4.29a.5.5 0 0 0-.454-.29H6.14L6.984.62A.5.5 0 0 0 6.12.174l-6 7A.5.5 0 0 0 .499 8h5.359l-.844 3.38a.5.5 0 0 0 .864.445l6-7a.5.5 0 0 0 .075-.534Z" />
                                </svg>
                                <div className="text-xs text-slate-500">Sponsored</div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Body */}
                <div className="text-sm text-slate-800 dark:text-slate-100 space-y-2 mb-5">
                    <p>Riding high 🌿</p>
                    <div className="relative !my-4">
                        <img className="block w-full" src={"bgimage.png"} width="590" height="332" alt="Feed 01" />
                        <div className="absolute left-0 right-0 bottom-0 p-4 bg-black bg-opacity-25 backdrop-blur-md">
                            <div className="flex items-center justify-between">
                                <div className="text-xs font-medium text-slate-300">Pre-Alpha screenshot</div>
                                <a className="text-xs font-medium text-indigo-400 hover:text-indigo-300" href="#0">
                                    Learn More -&gt;
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <footer className="flex items-center space-x-4">
                    {/* Like button */}
                    <button className="flex items-center text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                        <svg className="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                            <path d="M14.682 2.318A4.485 4.485 0 0011.5 1 4.377 4.377 0 008 2.707 4.383 4.383 0 004.5 1a4.5 4.5 0 00-3.182 7.682L8 15l6.682-6.318a4.5 4.5 0 000-6.364zm-1.4 4.933L8 12.247l-5.285-5A2.5 2.5 0 014.5 3c1.437 0 2.312.681 3.5 2.625C9.187 3.681 10.062 3 11.5 3a2.5 2.5 0 011.785 4.251h-.003z" />
                        </svg>
                        <div className="text-sm text-slate-500 dark:text-slate-400">4</div>
                    </button>
                    {/* Share button */}
                    <button className="flex items-center text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                        <svg className="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                            <path d="M13 7h2v6a1 1 0 0 1-1 1H4v2l-4-3 4-3v2h9V7ZM3 9H1V3a1 1 0 0 1 1-1h10V0l4 3-4 3V4H3v5Z" />
                        </svg>
                        <div className="text-sm text-slate-500 dark:text-slate-400">44</div>
                    </button>
                    {/* Replies button */}
                    <button className="flex items-center text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                        <svg className="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                            <path d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                        </svg>
                        <div className="text-sm text-slate-500 dark:text-slate-400">7</div>
                    </button>
                </footer>
            </div>
        </>
    )
}

function FeedRightContent(props){
    return (
        <div className="w-full hidden xl:block xl:w-72">
            <div
                className="lg:sticky lg:top-16 lg:h-[calc(100dvh-64px)] lg:overflow-x-hidden lg:overflow-y-auto no-scrollbar">
                <div className="md:py-8">

                    {/* Search form */}
                    <div className="mb-6">
                        <form className="relative">
                            <label htmlFor="feed-search-desktop" className="sr-only">
                                Search
                            </label>
                            <input id="feed-search-desktop"
                                   className="form-input w-full pl-9 bg-white dark:bg-slate-800" type="search"
                                   placeholder="Search…"/>
                            <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search">
                                <svg
                                    className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-3 mr-2"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"/>
                                    <path
                                        d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"/>
                                </svg>
                            </button>
                        </form>
                    </div>

                    {/* Blocks */}
                    <div className="space-y-4">

                        {/* Block 1 */}
                        <div
                            className="bg-slate-50 dark:bg-slate-800/20 p-4 rounded border border-slate-200 dark:border-slate-700">
                            <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-4">
                                Patch Notes
                            </div>
                            <ul className="space-y-3">
                                <li>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <div className="relative mr-3">
                                                <img className="w-8 h-8 rounded-full" src={"aeflogo.png"} width="32"
                                                     height="32" alt="Group 01"/>
                                            </div>
                                            <div className="truncate">
                                                <span
                                                    className="text-sm font-medium text-slate-800 dark:text-slate-100">0.0.6</span>
                                            </div>
                                        </div>
                                        <button
                                            className="text-xs inline-flex font-medium bg-indigo-100 dark:bg-indigo-500/30 text-indigo-600 dark:text-indigo-400 rounded-full text-center px-2.5 py-1">
                                            Current
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <div className="relative mr-3">
                                                <img className="w-8 h-8 rounded-full" src={"aeflogo.png"} width="32"
                                                     height="32" alt="Group 02"/>
                                            </div>
                                            <div className="truncate">
                                                <span
                                                    className="text-sm font-medium text-slate-800 dark:text-slate-100">0.0.5</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <div className="relative mr-3">
                                                <img className="w-8 h-8 rounded-full" src={"aeflogo.png"} width="32"
                                                     height="32" alt="Group 03"/>
                                            </div>
                                            <div className="truncate">
                                                <span
                                                    className="text-sm font-medium text-slate-800 dark:text-slate-100">0.0.4</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <div className="relative mr-3">
                                                <img className="w-8 h-8 rounded-full" src={"aeflogo.png"} width="32"
                                                     height="32" alt="Group 04"/>
                                            </div>
                                            <div className="truncate">
                                                <span
                                                    className="text-sm font-medium text-slate-800 dark:text-slate-100">0.0.3</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="mt-4">
                                <button
                                    className="btn-sm w-full bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500 shadow-none">View
                                    All
                                </button>
                            </div>
                        </div>

                        {/* Block 2 */}
                        <div
                            className="bg-slate-50 dark:bg-slate-800/20 p-4 rounded border border-slate-200 dark:border-slate-700">
                            <div
                                className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-4">Latest News
                            </div>
                            <ul className="space-y-3">
                                <li>
                                    <div className="text-sm mb-1">
                                        <a className="font-medium text-slate-800 hover:text-slate-900 dark:text-slate-100 dark:hover:text-white"
                                           href="#0">
                                            Tracking your website traffic on launch day 📈
                                        </a>
                                    </div>
                                    <div className="text-xs text-slate-500">248 comments</div>
                                </li>
                                <li>
                                    <div className="text-sm mb-1">
                                        <a className="font-medium text-slate-800 hover:text-slate-900 dark:text-slate-100 dark:hover:text-white"
                                           href="#0">
                                            Freemium model questions
                                        </a>
                                    </div>
                                    <div className="text-xs text-slate-500">47 comments</div>
                                </li>
                                <li>
                                    <div className="text-sm mb-1">
                                        <a className="font-medium text-slate-800 hover:text-slate-900 dark:text-slate-100 dark:hover:text-white"
                                           href="#0">
                                            Slack and Community
                                        </a>
                                    </div>
                                    <div className="text-xs text-slate-500">24 comments</div>
                                </li>
                                <li>
                                    <div className="text-sm mb-1">
                                        <a className="font-medium text-slate-800 hover:text-slate-900 dark:text-slate-100 dark:hover:text-white"
                                           href="#0">
                                            Who owns user onboarding in your company?
                                        </a>
                                    </div>
                                    <div className="text-xs text-slate-500">17 comments</div>
                                </li>
                                <li>
                                    <div className="text-sm mb-1">
                                        <a className="font-medium text-slate-800 hover:text-slate-900 dark:text-slate-100 dark:hover:text-white"
                                           href="#0">
                                            Questions from a very confused Web3 startup founder 🤔
                                        </a>
                                    </div>
                                    <div className="text-xs text-slate-500">9 comments</div>
                                </li>
                            </ul>
                            <div className="mt-4">
                                <button
                                    className="btn-sm w-full bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500 shadow-none">View
                                    All
                                </button>
                            </div>
                        </div>

                        {/* Block 3 */}
                        <div
                            className="bg-slate-50 dark:bg-slate-800/20 p-4 rounded border border-slate-200 dark:border-slate-700">
                            <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-4">
                                Media
                            </div>
                            <ul className="space-y-3">

                                {/* Media items */}
                                <li>
                                    <div className="flex items-center justify-between">
                                        <div className="grow flex items-center">
                                            <div className="relative mr-3">
                                                <img className="w-8 h-8 rounded-full" src={"aeflogo.png"} width="32"
                                                     height="32" alt="User 02"/>
                                            </div>
                                            <div className="truncate">
                                                <span
                                                    className="text-sm font-medium text-slate-800 dark:text-slate-100">Screenshot 1</span>
                                            </div>
                                        </div>
                                        <button
                                            className="text-xs inline-flex font-medium bg-indigo-100 dark:bg-indigo-500/30 text-indigo-600 dark:text-indigo-400 rounded-full text-center px-2.5 py-1">
                                            Show
                                        </button>
                                    </div>
                                </li>

                            </ul>
                            <div className="mt-4">
                                <button
                                    className="btn-sm w-full bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500 shadow-none">View
                                    All
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

function FeedLeftContent(props) {
    return (
        <div className="w-full md:w-60 mb-8 md:mb-0">
            <div
                className="md:sticky md:top-16 md:h-[calc(100dvh-64px)] md:overflow-x-hidden md:overflow-y-auto no-scrollbar">
                <div className="md:py-8">

                    {/* Title */}
                    <header className="mb-6">
                        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">News Feed ✨</h1>
                    </header>

                    {/* Search form */}
                    <div className="xl:hidden mb-6">
                        <form className="relative">
                            <label htmlFor="feed-search-mobile" className="sr-only">
                                Search
                            </label>
                            <input id="feed-search-mobile"
                                   className="form-input w-full pl-9 bg-white dark:bg-slate-800" type="search"
                                   placeholder="Search…"/>
                            <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search">
                                <svg
                                    className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-3 mr-2"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"/>
                                    <path
                                        d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"/>
                                </svg>
                            </button>
                        </form>
                    </div>

                    {/* Links */}
                    <div
                        className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-4 md:space-y-3 -mx-4">
                        {/* Group 1 */}
                        <div>
                            <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">
                                Official
                            </div>
                            <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap bg-white dark:bg-slate-800"
                                       href="#0">
                                        <svg className="w-4 h-4 shrink-0 fill-current text-indigo-500 mr-2"
                                             viewBox="0 0 16 16">
                                            <path
                                                d="M10 16h4c.6 0 1-.4 1-.998V6.016c0-.3-.1-.6-.4-.8L8.6.226c-.4-.3-.9-.3-1.3 0l-6 4.992c-.2.2-.3.5-.3.799v8.986C1 15.6 1.4 16 2 16h4c.6 0 1-.4 1-.998v-2.996h2v2.996c0 .599.4.998 1 .998Zm-4-5.99c-.6 0-1 .399-1 .998v2.995H3V6.515L8 2.32l5 4.194v7.488h-2v-2.995c0-.6-.4-.999-1-.999H6Z"/>
                                        </svg>
                                        <span className="text-sm font-medium text-indigo-500">All</span>
                                    </a>
                                </li>
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap"
                                       href="#0">
                                        <svg
                                            className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 mr-2"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M14.14 9.585h-.002a2.5 2.5 0 0 1-2 4.547 6.91 6.91 0 0 1-6.9 1.165 4.436 4.436 0 0 0 1.343-1.682c.365.087.738.132 1.113.135a4.906 4.906 0 0 0 2.924-.971 2.5 2.5 0 0 1 3.522-3.194Zm-4.015-7.397a7.023 7.023 0 0 1 4.47 5.396 4.5 4.5 0 0 0-1.7-.334c-.15.002-.299.012-.447.03a5.027 5.027 0 0 0-2.723-3.078 2.5 2.5 0 1 1 .4-2.014ZM4.663 10.5a2.5 2.5 0 1 1-3.859-.584 6.888 6.888 0 0 1-.11-1.166c0-2.095.94-4.08 2.56-5.407.093.727.364 1.419.788 2.016A4.97 4.97 0 0 0 2.694 8.75c.003.173.015.345.037.516A2.49 2.49 0 0 1 4.663 10.5Z"/>
                                        </svg>
                                        <span
                                            className="text-sm font-medium text-slate-600 dark:text-slate-300">Patch Notes</span>
                                    </a>
                                </li>
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap"
                                       href="#0">
                                        <svg
                                            className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 mr-2"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z"/>
                                        </svg>
                                        <span
                                            className="text-sm font-medium text-slate-600 dark:text-slate-300">Articles</span>
                                    </a>
                                </li>
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap"
                                       href="#0">
                                        <svg
                                            className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 mr-2"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M10.59 7.658 8 5.5 5.41 7.658A.25.25 0 0 1 5 7.466V0h6v7.466a.25.25 0 0 1-.41.192Z"/>
                                            <path
                                                d="M14 16H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2v2H2v12h12V2h-2V0h2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2Z"/>
                                        </svg>
                                        <span
                                            className="text-sm font-medium text-slate-600 dark:text-slate-300">Media</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* Group 2 */}
                        <div>
                            <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">
                                Community
                            </div>
                            <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap"
                                       href="#0">
                                        <svg
                                            className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 mr-2"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0ZM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0ZM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0Z"/>
                                        </svg>
                                        <span
                                            className="text-sm font-medium text-slate-600 dark:text-slate-300">Media</span>
                                    </a>
                                </li>
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap"
                                       href="#0">
                                        <svg
                                            className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 mr-2"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0ZM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0ZM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0Z"/>
                                        </svg>
                                        <span
                                            className="text-sm font-medium text-slate-600 dark:text-slate-300">Profiles</span>
                                    </a>
                                </li>
                                <li className="mr-0.5 md:mr-0 md:mb-0.5">
                                    <a className="flex items-center px-2.5 py-2 rounded whitespace-nowrap"
                                       href="#0">
                                        <svg
                                            className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 mr-2"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0ZM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0ZM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0Z"/>
                                        </svg>
                                        <span
                                            className="text-sm font-medium text-slate-600 dark:text-slate-300">Characters</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}