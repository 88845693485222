import {useAppContext} from "../contexts/appContext";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export default function Services(props){
    const {serverList, GetServers} = useAppContext();

    function FilterGame(gn){
        let servs = [];
        if(serverList && serverList.length > 0){
            for(let i = 0; i < serverList.length; i++){
                if(serverList[i].game === gn) servs.push(serverList[i]);
            }
        } else servs = [];
        return servs;
    }

    return (
        <div className={"flex flex-col w-full h-full text-white p-4 md:p-8"}>
            <div className={"flex flex-col h-full w-full bg-base-200 rounded-2xl overflow-y-auto border border-base-300 p-4 gap-2"}>
                <div className={"flex flex-row h-20 w-full justify-start items-end pl-4"}>AetherHouse Services:</div>
                <div className={"flex flex-col h-auto w-full my-2 justify-start items-start pl-2"}>
                    <div className={"flex flex-row w-auto h-auto gap-4 text-sm justify-start items-start"}>
                        <div className={"h-6 w-6 rounded-full bg-green-400"}></div>
                        <p>Account Services</p>
                    </div>
                    <div className={"flex flex-row w-auto h-auto my-2 gap-4 text-sm justify-start items-start"}>
                        <div className={"h-6 w-6 rounded-full bg-green-400"}></div>
                        <p>Web Services</p>
                    </div>
                </div>
                <div className={"flex flex-row h-20 w-full justify-start items-center pl-4"}>Aetherfract Defenders:
                </div>
                <div
                    className={"flex flex-col w-full h-auto justify-center items-start bg-base-200 text-primary border border-base-300 rounded-2xl overflow-hidden"}>
                    {<ServerTable servers={FilterGame("defenders")}/>}
                </div>
            </div>
        </div>
    )
}

function ServerTable(props) {
    const {servers} = props;
    return (
        <table className="table bg-base-100">
            <thead>
            <tr className={"border-b border-base-300"}>
                <th>Status</th>
                <th>Name</th>
                <th>Area</th>
                <th>Game Version</th>
                <th>Address</th>
                <th>Players</th>
            </tr>
            </thead>
            <tbody>
                {servers.map((server) => (
                    <tr key={server.serverID} className={"border-t border-base-300"}>
                        <th>
                            <div
                                className={"h-6 w-6 rounded-full " + (server.online ? " bg-green-400" : " bg-red-400")}></div>
                        </th>
                        <td>{server.ServerName}</td>
                        <td>{server.scene}</td>
                        <td>{server.version}</td>
                        <td>{server.serverAddress + ":" + server.port}</td>
                        <td>{server.onlineplayers.length.toString()}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}